import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { CurrencyTypes } from "enum";
import { ITransactions } from "interface/ITransactions";

export interface CreateTransaction {
    initiator?: number;
    type?: number;
    bank?: number;
    amount?: number;
    currency?: number;
    payment_method?: number;
    status?: number;
    extra?: string | null;
}

export interface AuthState {
    transactions: ITransactions | null;
    createTransaction: CreateTransaction | null;
    addressTransactions: {
        [key: string]: string;
    };
    success: boolean;
    isLoading: boolean;
}

const initialState: AuthState = {
    transactions: null,
    success: false,
    isLoading: false,
    addressTransactions: {
        [CurrencyTypes.USDT]: "TR9r38v3hi2SZwZFBQAcMuQatbrpxkB6C3",
        [CurrencyTypes.BNB]: "0x2c6d8955f92c5e16ed933563c42549234c221cd3",
        [CurrencyTypes.BTC]: "3BRCuhyUQRwRszMh5d2dYDjfDSYZc1ENrJ",
        [CurrencyTypes.DOGE]: "DEqHG6zWc4GVFr1drLZSvrBfnfdJRvkG6t",
        [CurrencyTypes.TRX]: "TVsaKwhXnb6hywdGahNc5u1ywNw7PpNuwB",
        [CurrencyTypes.OTHER]: "TVsaKwhXnb6hywdGahNc5u1ywNw7PpNuwB",
    },
    createTransaction: {
        type: 0,
        bank: 1,
        amount: 0,
        currency: 0,
        payment_method: 0,
        extra: null,
    },
};

const transactions = createSlice({
    name: "transactions",
    initialState,
    reducers: {
        addTransactions: (state, action: PayloadAction<ITransactions>) => {
            state.transactions = action.payload;
        },
        editCreateTransactions: (
            state,
            action: PayloadAction<CreateTransaction>
        ) => {
            state.createTransaction = {
                ...state.createTransaction,
                ...action.payload,
            };
        },
        clearCreateTransactions: (state) => {
            state.createTransaction = {
                type: 0,
                bank: 1,
                amount: 0,
                currency: 0,
                payment_method: 0,
                extra: null,
            };
        },
        setSuccess: (state, action: PayloadAction<boolean>) => {
            state.success = action.payload;
        },
        setLoadingTransactions: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    addTransactions,
    editCreateTransactions,
    setSuccess,
    setLoadingTransactions,
    clearCreateTransactions,
} = transactions.actions;

export default transactions.reducer;
