import { reverseEnum } from "utils/reverseEnum";

export enum TransactionTypes {
    "TOP UP" = 1,
    "WITHDRAW" = 2,
    "TRANSFER" = 3,
    "CONVERT COINS" = 4,
    "BUY MINING BOT" = 5,
    "BUYVIP" = 6,
}

export enum TransactionStatusTypes {
    Completed = 1,
    Failed = 2,
    Canceled = 3,
    Pending = 4,
}

export enum BankTypes {
    CRYPTOBANK = 1,
    CRYPTOPUMP = 2,
    BUYVIP = 3,
    FRIEND = 4,
}

export enum PaymentMethodTypes {
    CRYPTOCURRENCY = 1,
    VISA_MASTERCARD = 2,
}

export enum CurrencyTypes {
    USDT = 1,
    BTC = 2,
    BNB = 3,
    DOGE = 4,
    TRX = 5,
    OTHER = 1000,
}

export enum NotificationTypes {
    IMPORTANT = 1,
    FINANCE = 2,
    PROMOTIONS = 3,
}

export enum GameResults {
    NOT_FINISHED = 0,
    WIN = 1,
    LOSE = 2,
}

export enum GameDirection {
    UP = 1,
    DOWN = 2,
}

export const TransactionStatusTypesReverse: any = reverseEnum(
    TransactionStatusTypes
);
export const TransactionTypesReverse: any = reverseEnum(TransactionTypes);
export const BankTypesReverse: any = reverseEnum(BankTypes);
export const CurrencyTypesReverse: any = reverseEnum(CurrencyTypes);
